import React from "react";
import Fade from "react-reveal/Fade";

import { checkInternetConnectionAndNavigateToPublic } from "../utils/NavigationController.js";

function InitialHomePageLayout(props) {
  const [showPanel, setShowPanel] = React.useState(false);

  var slideIndex = 0;

  function showSlides() {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    if (slides.length > 0) {
      slides[slideIndex - 1].style.display = "block";
      setTimeout(showSlides, 2000); // Change image every 2 seconds
    }
  }

  React.useEffect(() => {
    setShowPanel(true);
    showSlides();
  });

  return (
    <>
      <div>
        <Fade right opposite when={showPanel}>
          <div className="metro panorama tile-section-custom scrollabale-x">
            <div className="panorama-sections">
              <div className="panorama-section">
                <div>
                  <h2>About Leopards</h2>
                  <div className="tile-column-span-2">
                    <a
                      className="tile widepeek bg-color-orange"
                      href="javascript:;"
                      onClick={() => {
                        setShowPanel(false);
                        props.parentCallbackLeopard();
                      }}
                    >
                      <div className="tile wide image slideshow-container bg-color-orange">
                        <div className="mySlides fade-slideshow">
                          <img
                            src="/assets/img/tiles-leopards/header-img.jpg"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="mySlides fade-slideshow">
                          <img
                            src="/assets/img/tiles-leopards/header-img2.jpg"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="mySlides fade-slideshow">
                          <img
                            src="/assets/img/tiles-leopards/header-img3.jpg"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="mySlides fade-slideshow">
                          <img
                            src="/assets/img/tiles-leopards/header-img4.jpg"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      {/* <div className="tile wide image search-leopard-mainpanel-background-img"></div> */}
                      <div className="tile wide text bg-color-orange">
                        <div className="text-header">Yala Leopards</div>
                        <br />
                        <div className="text4">
                          Search identified leopards, view their territories,
                          view leopard behaviours and more..
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="panorama-section">
                <div>
                  <h2>About Yala</h2>
                  <div className="tile-column-span-2">
                    <a
                      className="tile widepeek bg-color-redDark"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-yala");
                      }}
                    >
                      <div className="tile wide image about-about-yala-background-img"></div>
                      <div className="tile wide text bg-color-redDark">
                        <div className="text-header">Yala</div>
                        <br />
                        <div className="text4">
                          Learn more on the history, coverage, flora and fauna
                          and more information of the iconic Yala national park.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="panorama-section">
                <div>
                  <h2>About DWC</h2>
                  <div className="tile-column-span-2">
                    <a
                      className="tile widepeek bg-color-greenDark"
                      href="javascript:;"
                      onClick={() => {
                        setShowPanel(false);
                        props.parentCallbackDWC();
                      }}
                    >
                      <div className="tile wide image about-dwc-background-img"></div>
                      <div className="tile wide text bg-color-greenDark">
                        <div className="text-header">DWC</div>
                        <br />
                        <div className="text4">
                          About Department of Wildlife Conservation and more on
                          the conservation efforts..
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default InitialHomePageLayout;
