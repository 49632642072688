import React from "react";
import Fade from "react-reveal/Fade";
import { checkInternetConnectionAndNavigateToPublic } from "../utils/NavigationController.js";

function AboutDWCPageLayout(props) {
  const [showPanel, setShowPanel] = React.useState(false);

  React.useEffect(() => {
    setShowPanel(true);
  }, []);

  return (
    <>
      <div>
        <Fade right opposite when={showPanel}>
          <div className="metro panorama tile-section-custom scrollabale-x">
            <div className="panorama-sections">
              <div className="panorama-section">
                <div className="tile-column-span-1 back-button-tile-column">
                  <a
                    id="panorama-scroll-prev"
                    className="back-button-custom"
                    href="javascript:;"
                    onClick={() => {
                      setShowPanel(false);
                      props.parentCallback();
                    }}
                  />
                </div>
              </div>
              <div className="panorama-section">
                <div>
                  <h2>Department of Wildlife Conservation</h2>
                  <div className="tile-column-span-2">
                    <a
                      class="tile wide imagetext bg-color-green about-dwc-inner-tile-background-img"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-dwc");
                      }}
                    >
                      <div class="image-wrapper">
                        <span class="tile-icon-centered-custom fas fa-university"></span>
                      </div>
                      <div class="column-text">
                        <h2>About</h2>
                      </div>

                      <div class="app-label">
                        View DWC information.
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="panorama-section">
                <div>
                  <h2>Other National Parks and Sanctuaries</h2>
                  <div className="tile-column-span-2">
                    <a
                      class="tile wide imagetext bg-color-orange about-dwc-other-parks-inner-tile-background-img"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-dwc-parks");
                      }}
                    >
                      <div class="image-wrapper">
                        <span class="tile-icon-centered-custom fas fa-tree"></span>
                      </div>
                      <div class="column-text">
                        <h2>Other National Parks</h2>
                      </div>

                      <div class="app-label">Other DWC managed parks</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default AboutDWCPageLayout;
