import React from "react";
import Fade from "react-reveal/Fade";
import { navigateTo, navigateToPublic, checkInternetConnectionAndNavigateToPublic } from "../utils/NavigationController.js";
import Modal from "react-modal";

function AboutLeopardsPageLayout(props) {
  const [showPanel, setShowPanel] = React.useState(false);

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  function closeModal() {
    setModalIsOpen(false);
  }

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  Modal.setAppElement("#root");

  React.useEffect(() => {
    setShowPanel(true);
  }, []);

  function checkInternetConnectionAndNavigate() {
    console.log(window.navigator.onLine);
    if (window.navigator.onLine === true) {
      checkInternetConnectionAndNavigateToPublic("/match-identify-leopards?source=leopardcenter");
      // setModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  }

  return (
    <>
      <div>
        <Fade right opposite when={showPanel}>
          <div className="metro panorama tile-section-custom scrollabale-x">
            <div className="panorama-sections">
              <div className="panorama-section">
                <div className="tile-column-span-1 back-button-tile-column">
                  <a
                    id="panorama-scroll-prev"
                    className="back-button-custom"
                    href="/"
                    // onClick={() => {
                    //   setShowPanel(false);
                    //   props.parentCallback();
                    // }}
                  />
                </div>
              </div>

              <div className="panorama-section right-padding-about-diary">
                <div>
                  <h2>About Leopard Diary</h2>
                  <div className="tile-column-span-1">
                    <a
                      className="tile app bg-color-redDark"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-leopard-diary-team");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-users "></span>
                      </div>
                      <div className="app-label">Leopard Diary Team</div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="panorama-section">
                <div>
                  <h2>Yala Leopards</h2>
                  <div className="tile-column-span-2">
                    <a
                      className="tile wide imagetext bg-color-green search-leopard-background-img"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/search-identified-leopards");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-search"></span>
                      </div>
                      <div className="column-text">
                        <h2>Identified Leopards</h2>
                      </div>

                      <div className="app-label">
                        View 100+ identified leopards
                      </div>
                    </a>
                    <a
                      className="tile wide imagetext bg-color-blue"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic('/match-identify-leopards');
                        //checkInternetConnectionAndNavigate();
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-fingerprint"></span>
                      </div>
                      <div className="column-text">
                        <h2>Upload and Identify Leopards</h2>
                      </div>

                      <div className="app-label">
                        Upload your leopard photo to identify
                      </div>
                    </a>

                    {/* <a
                      className="tile wide imagetext bg-color-blue"
                      href="javascript:;"
                      onClick={() => {
                        navigateTo("/leopard-territories");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-map-marked-alt"></span>
                      </div>
                      <div className="column-text">
                        <h4>Leopard Territories</h4>
                      </div>
                      <div className="app-label">
                        View 80+ identified territories
                      </div>
                    </a> */}

                    <a
                      className="tile app bg-color-greenDark"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/our-identification-convention");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-check-double"></span>
                      </div>
                      <div className="app-label">Identification Method</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="panorama-section">
                <div>
                  <h2>About Leopards</h2>
                  <div className="tile-column-span-2">
                    <a
                      className="tile wide imagetext bg-color-orange about-leopard-background-img"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-srilankan-leopard");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-info"></span>
                      </div>
                      <div className="column-text">
                        <h2>Srilankan Leopard</h2>
                      </div>

                      <div className="app-label">(Panthera Pardus Kotiya)</div>
                    </a>

                    <a
                      className="tile app bg-color-purple"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-leopard-behaviours");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-photo-video"></span>
                      </div>
                      <div className="app-label">Leopard Behaviours</div>
                    </a>
                    <a
                      className="tile app bg-color-pink"
                      href="javascript:;"
                      onClick={() => {
                        checkInternetConnectionAndNavigateToPublic("/about-published-articles");
                      }}
                    >
                      <div className="image-wrapper">
                        <span className="tile-icon-centered-custom fas fa-newspaper "></span>
                      </div>
                      <div className="app-label">Published Articles</div>
                    </a>
                  </div>
                </div>

                <Modal
                  isOpen={modalIsOpen}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={modalStyles}
                >
                  <div className="no-internet-connection"></div>
                  <div className="modal-footer">
                    <button className="btn-round" onClick={() => closeModal()}>
                      Close
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default AboutLeopardsPageLayout;
