import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import TouchController from "./controller/TouchController";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <TouchController {...props} />} />

        <Route exact path="/about-leopards" 
          //render={(props) => <TouchController {...props} />} 
          render={props => (<TouchController redirectToLeopardsSubScreen={true} {...props} />)}/>
        
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
