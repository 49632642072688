import React from "react";
import configurations from "../config.json";

export const navigateTo = (urlPath) => {
  console.log("Navigate the fuck to " + configurations.WEBAPP_URL + urlPath);
  window.location.href = configurations.WEBAPP_URL + urlPath;
};

export const navigateToPublic = (urlPath) => {
  console.log(
    "Navigate the fuck to public" + configurations.PUBLIC_WEBAPP_URL + urlPath
  );
  window.location.href = configurations.PUBLIC_WEBAPP_URL + urlPath;
};


export const checkInternetConnectionAndNavigateToPublic = (urlPath) => {
  console.log(window.navigator.onLine);
  if (window.navigator.onLine === true) {
    navigateToPublic(urlPath + "?source=leopardcenter");
    // setModalIsOpen(true);
  } else {
    //setModalIsOpen(true);
  }
}
