import React from "react";
import InitialHomePageLayout from "../views/InitialHomePageLayout";
import AboutLeopardsPageLayout from "../views/AboutLeopardsPageLayout";
import AboutDWCPageLayout from "../views/AboutDWCPageLayout";

function TouchController(props) {
  const [redirectToLeopardsSubScreen, setRedirectToLeopardsSubScreen] =
    React.useState(props.redirectToLeopardsSubScreen);
  const [showLeopardsPanel, setShowLeopardsPanel] = React.useState(false);
  const [showDWCPanel, setShowDWCPanel] = React.useState(false);
  const [showMainPanel, setShowMainPanel] = React.useState(true);
  const [showIdentifyPanel, setShowIdentifyPanel] = React.useState(false);

  const openLeopardMenuCallbackFunction = () => {
    setShowMainPanel(false);
    setShowLeopardsPanel(true);
  };

  const openDWCMenuCallbackFunction = () => {
    setShowMainPanel(false);
    setShowDWCPanel(true);
  };

  const openMainMenuCallbackFunction = (openMainPanel) => {
    setShowLeopardsPanel(false);
    setShowDWCPanel(false);
    setShowMainPanel(true);
  };

  React.useEffect(() => {
    console.log(JSON.stringify(props.redirectToLeopardsSubScreen));
    if (redirectToLeopardsSubScreen) {
      setShowLeopardsPanel(true);
    }
    setShowMainPanel(true);
  }, []);

  return (
    <>
      <img
        src="assets/img/landing-background.jpg"
        alt="Yala Leopard Center"
        className="bg"
      />

      <div id="wrap">
        <div id="nav-bar">
          <div className="pull-left">
            <div id="header-container">
              <h2 className="welcome-header-custom">Welcome to</h2>
              <h1>Yala Leopard Center</h1>
            </div>
          </div>
        </div>

        {showLeopardsPanel && (
          <AboutLeopardsPageLayout
            parentCallback={openMainMenuCallbackFunction}
          />
        )}

        {showDWCPanel && (
          <AboutDWCPageLayout parentCallback={openMainMenuCallbackFunction} />
        )}

        {showMainPanel && (
          <InitialHomePageLayout
            parentCallbackLeopard={openLeopardMenuCallbackFunction}
            parentCallbackDWC={openDWCMenuCallbackFunction}
          />
        )}
      </div>

      <div id="wrap" className="footer-panel">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <footer>
          <img
            className="footer-logo"
            src="assets/img/leopard-center-logo.png"
          ></img>
          <br />
          <br />
          <p>
            {
              "    © App Designed And Developed By : Chathura Dushmantha, Pramod Sendanayake    "
            }
          </p>
        </footer>
      </div>
    </>
  );
}

export default TouchController;
